import SentryManager from '../manager/SentryManager';
import { waitForElementToAppear } from 'ui-shepherd-react/utils/observerUtils';
const MAX_WAIT_TIMEOUT = 10000;
export { waitForElementToAppear } from 'ui-shepherd-react/utils/observerUtils';
export const waitForAttachToElementToAppearOrFireSentry = async (tourId, stepId, options, timeout = MAX_WAIT_TIMEOUT) => {
  let timeoutId = 0;

  // If the element doesn't load in a certain period, error out
  const timeoutPromise = new Promise((_, reject) => {
    timeoutId = setTimeout(() => {
      reject(new Error());
    }, timeout);
  });
  try {
    const maybeElement = await Promise.race([waitForElementToAppear(options), timeoutPromise]);
    if (maybeElement instanceof HTMLElement) return maybeElement;
  } catch (_unused) {
    SentryManager.reportElementLoadTimeout(tourId, stepId);
  } finally {
    window.clearTimeout(timeoutId);
  }
  return null;
};
export const waitForElementToDisappear = (elementSelector, timeoutInMs) => {
  return new Promise((resolve, reject) => {
    const getAttachedElement = () => document.querySelector(elementSelector);
    const timer = {
      intervalId: 0,
      timeoutTimer: 0
    };
    timer.intervalId = setInterval(() => {
      if (!getAttachedElement()) {
        resolve({});
        clearInterval(timer.intervalId);
        clearTimeout(timer.timeoutTimer);
        return;
      }
    }, 300);
    timer.timeoutTimer = setTimeout(() => {
      clearInterval(timer.intervalId);
      reject(new Error(`Timeout waiting for element '${elementSelector}' to disappear`));
    }, timeoutInMs);
  });
};