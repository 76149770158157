import { createCopilotChannel } from 'copilot-toolkit/channel/createCopilotChannel';
import { renderRoot } from './root';
export const renderWithNavCopilot = (app, {
  layoutType,
  useCreateRoot
}) => {
  try {
    const channel = createCopilotChannel();
    channel.setState({
      layoutType
    });
  } catch (e) {
    // Failed to set layout type
    console.error(e);
  }
  return renderRoot(app, {
    copilotLayoutType: layoutType,
    legacyRoot: !useCreateRoot
  });
};