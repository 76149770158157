import { ApolloLink, Observable } from '@apollo/client';
import Raven from 'raven-js';
const getOperationDetails = operation => {
  const {
    operationName,
    variables
  } = operation;
  const context = operation.getContext();
  const correlationId = context.correlationId;
  const isMutation = operation.query.definitions && operation.query.definitions.some(definition => definition.kind === 'OperationDefinition' && definition.operation === 'mutation');
  return {
    operationName,
    isMutation,
    correlationId,
    // only include variables if query is not a mutation.
    // Mutations can contain PII/GDPR-sensitive data
    variables: isMutation ? 'mutation' : JSON.stringify(variables)
  };
};
function shouldReportError(error, operationContext) {
  const errorCode = error === null || error === void 0 ? void 0 : error.errorCode;
  const httpStatus = error === null || error === void 0 ? void 0 : error.status;
  const errorMessage = error === null || error === void 0 ? void 0 : error.message;
  if (operationContext !== null && operationContext !== void 0 && operationContext.dangerouslyDisableAllGlobalErrorReporting) {
    return false;
  } else if (operationContext !== null && operationContext !== void 0 && operationContext.disableGlobalErrorReportingForMatches && Array.isArray(operationContext === null || operationContext === void 0 ? void 0 : operationContext.disableGlobalErrorReportingForMatches) && operationContext.disableGlobalErrorReportingForMatches.length > 0) {
    const errorData = {
      errorCode,
      httpStatus,
      errorMessage
    };
    const match = operationContext.disableGlobalErrorReportingForMatches.find(matcher => Object.keys(matcher).reduce((acc, key) => {
      if (!acc) {
        return false;
      }
      const matchValue = matcher[key];
      const errorValue = errorData[key];
      if (matchValue === errorValue) {
        return true;
      }
      return typeof errorValue === 'string' && typeof matchValue === 'string' && matchValue.length > 0 && errorValue.includes(matchValue);
    }, Object.keys(matcher).length > 0));
    if (match) {
      return false;
    }
  }
  return true;
}
export default new ApolloLink((operation, forward) => new Observable(observer => {
  const sub = forward(operation).subscribe({
    next(result) {
      observer.next(result);
      if (!result.errors || !result.errors.length) {
        return;
      }
      const operationContext = operation.getContext();
      for (const err of result.errors) {
        if (!shouldReportError(err, operationContext)) {
          continue;
        }
        Raven.captureException(new Error(`hubspot-apollo-client: ${err.message}`), {
          extra: Object.assign({}, getOperationDetails(operation))
        });
      }
    },
    error(err) {
      const operationDetails = getOperationDetails(operation);
      const operationContext = operation.getContext();
      if (!shouldReportError(err, operationContext)) {
        observer.error(err);
        return;
      }
      if (err.errorCode === 'TIMEOUT') {
        Raven.captureException(new Error('hubspot-apollo-client: graphQLTimeout'), {
          extra: Object.assign({
            // Hoist these 3 attributes out of the originalError so they can be filtered on in logfetch
            errorCode: err === null || err === void 0 ? void 0 : err.errorCode,
            httpStatus: err === null || err === void 0 ? void 0 : err.status,
            errorMessage: err === null || err === void 0 ? void 0 : err.message
          }, operationDetails)
        });
      } else {
        Raven.captureException(new Error('hubspot-apollo-client: networkError'), {
          extra: Object.assign({
            // Hoist these 3 attributes out of the originalError so they can be filtered on in logfetch
            errorCode: err === null || err === void 0 ? void 0 : err.errorCode,
            httpStatus: err === null || err === void 0 ? void 0 : err.status,
            errorMessage: err === null || err === void 0 ? void 0 : err.message
          }, operationDetails)
        });
      }
      observer.error(err);
    },
    complete() {
      observer.complete();
    }
  });
  return () => {
    if (sub) {
      sub.unsubscribe();
    }
  };
}));