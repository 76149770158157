import { Component } from 'react';
import Raven from 'raven-js';
export default class OnboardingTourErrorBoundary extends Component {
  componentDidCatch(error) {
    Raven.captureException(error, {
      extra: {
        tourId: this.props.tourId
      },
      tags: {
        'onboarding-tours': 'true'
      }
    });
  }
  render() {
    return this.props.children;
  }
}