import { populateTourWithCopy } from './tourCopy';
import SentryManager from '../manager/SentryManager';
async function importTourConfig(tourId) {
  try {
    return await import( /* webpackChunkName: "onboarding-tour-[request]" */
    /* webpackMode: "lazy" */
    `../tours/${tourId}`);
  } catch (error) {
    if (process.env.NODE_ENV !== 'production' && error.code === 'MODULE_NOT_FOUND') {
      console.warn(`%c🚧 Loaded WIP tour config for "%s"%c\n
This unfinished tour won't be included in production.\n
✅ To enable it, remove 'wip.' from the alias and add the required AT.`, 'color: orange; font-weight: bold;', tourId, '');
      return await import( /* webpackChunkName: "onboarding-tour-[request]" */
      /* webpackMode: "lazy" */
      `../tours/${tourId}.wip`);
    }
    throw error;
  }
}
export function getTourById(tourId) {
  return importTourConfig(tourId).then(getTourConfig => {
    // Call default export of tour config file, function can be async
    return (getTourConfig.default || getTourConfig)();
  }).then(maybeTour => {
    const tourConfig = maybeTour.default || maybeTour;
    const tourWithCopy = populateTourWithCopy(tourConfig);
    return tourWithCopy;
  }).catch(error => {
    const [sentryError] = SentryManager.reportLoadTourError(error, tourId);
    throw sentryError;
  });
}
export function getTourFromConfig(tourConfig) {
  let tourId = undefined;
  return new Promise(resolve => {
    if (typeof tourConfig === 'function') {
      resolve(tourConfig());
    } else {
      resolve(tourConfig);
    }
  }).then(tour => {
    tourId = tour.alias;
    if (!tourId) {
      throw new Error('Tour configs must have an alias');
    }
    const tourWithCopy = populateTourWithCopy(tour);
    return tourWithCopy;
  }).catch(error => {
    const [sentryError] = SentryManager.reportLoadTourError(error, tourId || tourConfig.alias || '-unknown-');
    throw sentryError;
  });
}