import { getTourIdFromQueryParams } from './urlUtils';
import { parseJSON, sessionStorage } from './storageUtil';
import { getLinkedTourId } from './linkedTourUtils';
const ONBOARDING_TOUR_STEP_CONTENT_OVERRIDES = 'ONBOARDING_TOUR_STEP_CONTENT_OVERRIDES';
export const IN_APP_SIDEPANEL_EXPOSURE_STORAGE_KEY = 'Growth:InAppSidePanel:ShowSidepanel';
export const IN_APP_SIDEPANEL_ORIGINATING_CUSTOMIZATION_TOUR_KEY = 'Growth:InAppSidePanel:OriginatingCrmCustomizationTour';
export const CRM_CUSTOMIZATIONS_CONTEXT = 'crmCustomizations';
export const USER_GUIDE_NAV_TOOLTIP_CONTEXT = 'userGuideNavTooltip';
export const getShouldEnableTour = () => {
  if (getTourIdFromQueryParams()) {
    return true;
  }
  return Boolean(getLinkedTourId());
};
export const getAllTourContentOverrides = () => {
  const sessionContentOverrides = sessionStorage.getItem(ONBOARDING_TOUR_STEP_CONTENT_OVERRIDES);
  const allContentOverrides = parseJSON(sessionContentOverrides);
  return allContentOverrides || {};
};
export const getTourStepContentOverrides = (tourId, stepId) => {
  if (!tourId || !stepId) {
    return {};
  }
  const allContentOverrides = getAllTourContentOverrides();
  const tourContentOverrides = allContentOverrides && allContentOverrides[tourId];
  const stepContentOverrides = tourContentOverrides && tourContentOverrides[stepId];
  return stepContentOverrides || {};
};
export const setTourStepContentOverrides = (...overrides) => {
  const allOverrides = getAllTourContentOverrides();
  for (const {
    tourId,
    stepId,
    overrides: stepContentOverrides
  } of overrides) {
    if (!allOverrides[tourId]) {
      allOverrides[tourId] = {};
    }
    allOverrides[tourId][stepId] = stepContentOverrides;
  }
  sessionStorage.setItem(ONBOARDING_TOUR_STEP_CONTENT_OVERRIDES, JSON.stringify(allOverrides));
};
export const removeTourStepContentOverrides = () => {
  sessionStorage.removeItem(ONBOARDING_TOUR_STEP_CONTENT_OVERRIDES);
};
export const openCrmCustomizationsSidepanel = tourId => {
  sessionStorage.setItem(IN_APP_SIDEPANEL_ORIGINATING_CUSTOMIZATION_TOUR_KEY, tourId);
  sessionStorage.setItem(IN_APP_SIDEPANEL_EXPOSURE_STORAGE_KEY, CRM_CUSTOMIZATIONS_CONTEXT);
};
export const hasOpenedCrmCustomizationsSidepanel = tourId => {
  return sessionStorage.getItem(IN_APP_SIDEPANEL_ORIGINATING_CUSTOMIZATION_TOUR_KEY) === tourId && sessionStorage.getItem(IN_APP_SIDEPANEL_EXPOSURE_STORAGE_KEY) === CRM_CUSTOMIZATIONS_CONTEXT;
};
export const openUserGuideNavTooltip = () => {
  sessionStorage.setItem(IN_APP_SIDEPANEL_EXPOSURE_STORAGE_KEY, USER_GUIDE_NAV_TOOLTIP_CONTEXT);
};