import { __TEST_ONLY__clearGlobal } from './persistence/global';
import { __TEST_ONLY__DISABLE_IDB } from './persistence/indexeddb';
import { __TEST_ONLY__disableWorkerTimeout } from './worker/runWorker';
export { __TEST_ONLY__DISABLE_IDB } from './persistence/indexeddb';
export { __TEST_ONLY__clearGlobal } from './persistence/global';
export const persistPromiseTestSetup = () => {
  __TEST_ONLY__DISABLE_IDB();
  __TEST_ONLY__clearGlobal();
  __TEST_ONLY__disableWorkerTimeout();
  return Promise.resolve();
};